import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionHeader from "./SectionHeader";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "../styles/styles.module.scss";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import EventNote from "@mui/icons-material/EventNote";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  text1: {
    padding: `0 ${theme.spacing(3)}`,
    fontSize: "2rem",
    color: theme.palette.primary.standard,
  },
  text2: {
    fontSize: "1.5rem",
    color: theme.palette.primary.standard,
  },

  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
 
 
  image: {
    margin: "0 auto",
    maxWidth: 1170,
    display: "block",
    height: "auto",
    width: "100%",
    // borderRadius: "20px",

    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
  },
  imageContainer: {
    borderRadius: "0px",
    border: "0px  solid",
  },
}));

export default function Example2(props) {
  const classes = useStyles();

  return (
    <Section id={props.name}
      bgColor="#fb3569"
      size={"small"}
      // bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid className={classes.row} container={true} alignItems="center">
          <Grid item >
            <Grid item>
              <Typography className={classes.text1} variant="h4" gutterBottom={true}>
                You should be able to organize your contacts the way you want
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text2} variant="body1" gutterBottom={true}>
                Now you can give yourself and your users the control needed to quickly work with large contact
                databases.
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.text2} variant="body1" gutterBottom={true}>
                Choose how many seats you need ContactsManager has flexible plans that work for a solo team member, your
                whole team, or the entire company.
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid >
              {/* </Grid> */}

              <figure>
                <div className={classes.imageContainer}>
                  <img src={"images/CM1.webp"} alt="illustration" className={classes.image} />
                </div>
              </figure>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

// export default HomeCTA2;
