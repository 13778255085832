import React from "react";
import Navbar from "./../components/Navbar";
import HomePage from "./home";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import ContactsPage from "./contacts";
import Calender from "./calender";
import WaitListPage from "./waitlist";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import LandingPage1 from "./LandingPage1.js";
import LandingPage2 from "./LandingPage2.js";
import LandingPage3 from "./LandingPage3.js";
import LandingPage4 from "./LandingPage4.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { ThemeProvider } from "./../util/theme.js";
import styles from "../styles/styles.module.scss";

function App(props) {

    console.log('365APPS  App  window.location:', window.location.pathname);


  
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <div id="appcontainer" className={styles.App}>
            <Navbar color="default" logo="images/365APPS.png" logoInverted="images/365APPS - light.png" />

            <Switch>
              <Route exact path="/" component={HomePage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/dashboard" component={DashboardPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/purchase/:plan/" component={PurchasePage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/Contacts" component={ContactsPage} />

              <Route exact path="/calender" component={Calender} />

              <Route exact path="/waitlist" component={WaitListPage} />

              <Route exact path="/CustomLists" component={LandingPage1} />
              <Route exact path="/AgendaViews" component={LandingPage2} />
              <Route exact path="/MobileViews" component={LandingPage3} />
              <Route exact path="/Interface" component={LandingPage4} />

              <Route exact path="/sitemap.xml" component={"../../public/sitemap.xml"} />

              {/* <Route exact path="/new-page" component={NewPagePage2} /> */}

              <Route exact path="/firebase-action" component={FirebaseActionPage} />

              <Route component={NotFoundPage} />
            </Switch>

            {/* {window.location.pathname =="/customLists" ? null: ( */}
              <Footer
                bgColor="secondary"
                size="normal"
                bgImage=""
                bgImageOpacity={1}
                description="A short description of what you do here"
                copyright={`© ${new Date().getFullYear()} 365Apps`}
                logoInverted="images/365APPS - light.png"
                logo="images/365APPS.png"
                sticky={true}
              />
              
              {/* )} */}

          </div>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
