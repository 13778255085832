import React from "react";

function Calender(props) {
  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/ryan365apps"
         style={{
           minWidth:"320px",
           height:"630px"
         }}
      ></div>
      <script type="text/javascript" 
      src="https://assets.calendly.com/assets/external/widget.js" async>
        
      </script>
    </div>
  );
}

export default Calender;
