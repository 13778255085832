import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionHeader from "./SectionHeader";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "../styles/styles.module.scss";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import EventNote from "@mui/icons-material/EventNote";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  text1: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  text2: {
    padding: `0 ${theme.spacing(3)}px`,
  },

  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    maxWidth: 1170,
    display: "block",
    height: "150px",

    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
  },
  imageContainer: {
    borderRadius: "0px",
    border: "0px  solid",
  },

  sectionHeader: {
    padding: "10px",
    marginBottom: "20px",
    border: "0px  solid",
  },
  sectionHeader2: {
    margin: "0 auto",
    width: "60%",
  },
  button: {
    width: 300,
    height: 60,
    fontSize: "20px",
    color: "#fff",
    fontWeight: 400,
    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    backgroundColor: "#3d5afe",
    "&:hover": {
      backgroundColor: "#e91e63",
      opacity: [0.9, 0.8, 0.7],
      boxShadow: "rgb(0 0 0 / 15%) 0px 1px 1px 1px, rgb(101 111 119 / 35%) 0px 20px 20px -12px",
    },
  },
  subtitle: {
    fontSize: "18px",
    justifyContent: "center",
  },
}));

export default function Quad(props) {
  const classes = useStyles();

  return (
    <Section
      id={props.name}
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography className={classes.text1} variant="h4" gutterBottom={true}>
                Making your Teams easier to work with
              </Typography>

              <Typography className={classes.text1} variant="h2" gutterBottom={true}>
                Solving the challenge of the distributed workforce
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <OfflineBoltIcon fontSize="large"></OfflineBoltIcon>
              <Typography className={classes.text2} variant="h5" gutterBottom={true}>
                Prominent Presence indicators
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <WorkOffIcon fontSize="large"></WorkOffIcon>
              <Typography className={classes.text2} variant="h5" gutterBottom={true}>
                See your colleagues Working Hours
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <AccessAlarmIcon fontSize="large"></AccessAlarmIcon>

              <Typography className={classes.text2} variant="h5" gutterBottom={true}>
                Time Differences
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <EventNote fontSize="large"></EventNote>
              <Typography className={classes.text2} variant="h5" gutterBottom={true}>
                See Free/Busy Schedules without Outlook
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column" spacing={4}>
                <Grid item xs={12} textAlign="center" className={classes.subtitle}>
                  {props.subtitle}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

// export default HomeCTA2;
