import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SectionHeader from "./SectionHeader";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    maxWidth: 1170,
    display: "block",
    height: "150px",

    boxShadow:
      "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
  },
  imageContainer: {
    borderRadius: "0px",
    border: "0px  solid",
  },

  sectionHeader: {
    padding: "10px",
    marginBottom: "20px",
    border: "0px  solid",
  },
  sectionHeader2: {
    margin: "0 auto",
    width: "60%",
  },
}));

function HomeSection1(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Grid container direction="column" spacing={4}>
                <Grid item xs={6}>
                  <Button 
                    
                  variant="contained">{props.Buttontitle}</Button>
                </Grid>
                <Grid item xs={6}>
                  {props.subtitle}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <figure>
                <div className={classes.imageContainer}>
                  <img
                    src={props.image}
                    alt="illustration"
                    className={classes.image}
                  />
                </div>
              </figure>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default HomeSection1;
