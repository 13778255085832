import React from "react";
import { useRef } from "react";

import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import { Link } from "../util/router.js";
import { useAuth } from "../util/auth.js";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import { CtaButton } from "../components/CtaSection";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { InlineWidget } from "react-calendly";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#fff",
    borderTop: "8px solid #fb3569",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

const MyPage = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "7636048",
    formId: "c57e955d-ee71-4705-a3ac-9b24dd59e41c",
    target: "#my-hubspot-form",
  });

  return (
    <div>
      <div id="my-hubspot-form"></div>
    </div>
  );
};
const PricingEnterpriseSection = () => {
  // function PricingEnterpriseSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  const [value, setValue] = React.useState(8000);
  const [userValue, setuserValue] = React.useState(8);
  const [seatvalue, setseatValue] = React.useState(1000);
  const [userNumbers, setuserNumbers] = React.useState();

  const [bigslider, setBigSlider] = React.useState(false);

  const item = {
    id: "enterprise",
    name: "Enterprise ",
    price: value,
    perks: [
      "Same day support",
      "All features",
      "Organize contacts and team mates",
      "Search",
      "Folders",
      "Free Busy",
      "Quick Actions",
      "Global address list",
    ],
  };

  const items = [
    {
      key: 0,
      users: 1000,
      basePrice: 0,
      userPrice: 8,
      name: "1000 Seats",
    },
    {
      key: 1,
      users: 2000,
      basePrice: 0,
      userPrice: 7.5,
      name: "2000 Seats",
    },
    {
      key: 2,
      users: 3000,
      basePrice: 0,
      userPrice: 7,
      name: "3000 Seats",
    },
    {
      key: 3,
      users: 4000,
      basePrice: 0,
      userPrice: 6.5,
      name: "4000 Seats",
    },
    {
      key: 4,
      users: 5000,
      basePrice: 0,
      userPrice: 6,
      name: "5000 Seats",
    },
  ];
  const timeoutIdRef = useRef(null);

  /**
   * Handle the change event for an input element.
   * This function does the following:
   * 1. Convert the input value to an integer.
   * 2. Update the seat value with the new integer value.
   * 3. Cancel any pending timeout from previous input events.
   * 4. After a delay (500ms), find the corresponding item for the entered seat value.
   * 5. Calculate and set the total value and per-user value based on the found item.
   */
  const handleSeatInputChange = (event) => {
    // Convert input value to an integer.
    let enteredSeatValue = parseInt(event.target.value);
    if (enteredSeatValue < 50) {
      enteredSeatValue= 50
    } else {
      // Update seat value with the new integer value.
      enteredSeatValue= enteredSeatValue
    }
    setseatValue(enteredSeatValue);
    console.log("365APPS  handleSeatInputChange  enteredSeatValue:", enteredSeatValue);
    // Clear any pending timeouts.
    clearTimeout(timeoutIdRef.current);

    // Set a new timeout for 500ms.
    timeoutIdRef.current = setTimeout(() => {
      // Find the item corresponding to the entered seat value.
      const matchedItem = items.filter(
        (item) => enteredSeatValue <= item.users && enteredSeatValue > item.users - 1000
      )[0];

      console.log("handleSeatInputChange ~ matchedItem:", matchedItem);

      if (matchedItem) {
        const { basePrice, userPrice } = matchedItem;

        // Calculate the total value based on the matched item.
        const totalValue = enteredSeatValue * userPrice + basePrice;
        console.log("365APPS  timeoutIdRef.current=setTimeout  totalValue:", totalValue);
        console.log(
          "365APPS  timeoutIdRef.current=setTimeout  (totalValue / enteredSeatValue).toPrecision(3):",
          (totalValue / enteredSeatValue).toPrecision(3)
        );

        // Calculate and set the per-user value.
        setValue(totalValue);
        setuserValue((totalValue / enteredSeatValue).toPrecision(3));
      }
    }, 500);
  };

  return (
    <Section size={"medium"}>
      <Container>
        <SectionHeader
          title="Pricing Calculator for Organisations"
          // subtitle={props.subtitle}
          size={3}
          textAlign="center"
        />

        <Grid container={true} justify="center" spacing={4}>
          <Grid item={true} xs={12} md={6}>
            <Card
              className={classes.card}
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderTop: "8px #fb3569 solid",
              }}
            >
              <CardContent className={classes.cardContent}>
                <Typography variant="h6" component="h2">
                  Choose the number of seats ( minimum 50 seats )
                </Typography>
                <Box className={classes.price} mt={1}>
                  <TextField
                    type="number"
                    label="Number of Seats"
                    value={seatvalue}
                    onChange={handleSeatInputChange}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: "51" }}
                  />
                </Box>
                <Box className={classes.price} mt={1}>
                  <Typography variant="h3">{seatvalue}</Typography>{" "}
                  <Typography
                    variant="h4"
                    color="textSecondary"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {" "}
                    Seats
                  </Typography>
                </Box>
                {item.description && (
                  <Box mt={2}>
                    <Typography component="p" color="textSecondary">
                      {item.description}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          {/* </Grid>
        <Grid container={true} justify="center" spacing={6}> */}
          <Grid item={true} xs={12} md={6}>
            <Card
              className={classes.card}
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderTop: "8px #fb3569 solid",
              }}
            >
              <CardContent className={classes.cardContent}>
                {item.description && (
                  <Box mt={2}>
                    <Typography component="p" color="textSecondary"></Typography>
                  </Box>
                )}
                <Typography variant="h6" component="h2"></Typography>
                <Box className={classes.price} mt={1}>
                  <Typography variant="h4">${item.price}</Typography>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    per Year
                  </Typography>
                </Box>

                <Box className={classes.price} mt={1}>
                  <Typography variant="h4">${userValue}</Typography>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    {" "}
                    per User per Year
                  </Typography>
                </Box>

                {item.description && (
                  <Box mt={2}>
                    <Typography component="p" color="textSecondary">
                      {item.description}
                    </Typography>
                  </Box>
                )}
                <Box mt={2}>
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 2,

                      fontWeight: "bold",
                    }}
                  >
                    Statistics show that 40 % of workers in large organizations waste up to 30 minutes a day searching
                    for space to collaborate <br></br>
                    Source: AskCody
                  </Typography>
                  {/* <Link href="https://cdn2.hubspot.net/hubfs/3033025/AskCody-eBook-MeetingManagement.pdf?t=1539261183701"
                  target={"_blank"}
                >
                  <Typography
                variant="body1"
                sx={{
                  ml: 2,
                  fontWeight: "bold",
                }}
              >
                </Typography>
                </Link> */}
                </Box>

                <Box mt="auto" pt={3}>
                  {/* <CtaButton size="1rem"></CtaButton> */}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item={true} xs={12} md={12}>
            <Card className={classes.card} variant="outlined">
              <CardContent className={classes.cardContent}>
                <Box mt={0}>
                  <Typography variant="h6" component="h2">
                    Large Enterpise
                  </Typography>
                </Box>
                <Box className={classes.price} mt={2}>
              
                  <Typography variant="h4">Over 10,000 users?</Typography>
                </Box>
                <Box mt="auto" pt={3}>
                  <CtaButton size="1rem"></CtaButton>
                </Box>
              </CardContent>
            </Card>
          </Grid> */}

          <Grid item={true} xs={12} md={6}>
            <Card
              className={classes.card}
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderTop: "8px #fb3569 solid",
              }}
            >
              <CardContent className={classes.cardContent}>
                <Box mt={0}>
                  <Typography variant="h6" component="h2">
                    Enquiry form
                  </Typography>
                </Box>
                <Box mt={2}>
                  <HubspotProvider>
                    <MyPage />
                  </HubspotProvider>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Card
              className={classes.card}
              variant="outlined"
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
                borderTop: "8px #fb3569 solid",
              }}
            >
              <CardContent className={classes.cardContent}>
                <Box mt={2}>
                  <InlineWidget
                    styles={{
                      height: "1000px",
                    }}
                    url="https://calendly.com/ryan365apps/contacts-manager-demo-beta-program-introduction"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default PricingEnterpriseSection;
