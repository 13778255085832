import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SectionHeader from "./SectionHeader";
import Button from "@mui/material/Button";
import { Link } from "./../util/router.js";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: "center",
  },
}));

export function CtaButton(props) {
  const classes = useStyles();
  const theme = useTheme();

  const label = props.label ? props.label : "Join Waiting List";

  return (
    <div>
      {/* <Button
        component={Link}
        to={"/waitlist"}
        variant="contained"
        sx={{
          fontSize: props.size ? props.size : "1.5rem",
        }}
      >
        {label}
      </Button> */}

  

    
      <Button
        className={classes.customButton}
        target="_blank"
        variant="contained"
        sx={{
          fontSize: props.size ? props.size : "1.0rem",
        }}        
        href={"https://teams.microsoft.com/l/app/29a37c62-cef0-4d57-ae0b-d5af9cacdd01?source=downloads-view"}
      >
        Add to Teams
      </Button>
    </div>
  );
}
