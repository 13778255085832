// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  
  starterAnnual : process.env.REACT_APP_PRICE_STARTER_MONTHLY_STRIPE,
  starterMonthly : process.env.REACT_APP_PRICE_STARTER_ANNUAL_STRIPE,
  proAnnual : process.env.REACT_APP_PRICE_PRO_ANNUAL_STRIPE,
  proMonthly : process.env.REACT_APP_PRICE_PRO_MONTHLY_STRIPE,
  businessAnnual : process.env.REACT_APP_PRICE_BUSINESS_ANNUAL_STRIPE,
  businessMonthly : process.env.REACT_APP_PRICE_BUSINESS_MONTHLY_STRIPE
  
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  //console.log('~ ENV', process.env);
  //console.log('~ planId', planId);
  //console.log('~ stripePriceIds[planId];', stripePriceIds[planId]);
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  //console.log('~ stripePriceId', stripePriceId);
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}
