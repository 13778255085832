import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Newsletter from "./Newsletter";
import { InlineWidget } from "react-calendly";

function NewsletterSection(props) {
  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container>
        <Grid
          container={true}
          alignItems="center"
          justify="center"
          direction="column"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item={true}>
            <Typography variant="h2" gutterBottom={true}>
              Contacts Manager is launching soon
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container={true}
          // alignItems="center"
          // justify="center"

          // style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item={true} xs={12} md={6}> 
          
            <Typography variant="h4" gutterBottom={true}  textAlign="center">
              Get early access
            </Typography>
          
            <Newsletter
              // buttonText={props.buttonText}
              // buttonColor={props.buttonColor}
              inputPlaceholder={props.inputPlaceholder}
              subscribedMessage={props.subscribedMessage}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Typography variant="h4" gutterBottom={true}  textAlign="center">
              Book a demo
            </Typography>
          <Grid item={true}>
          </Grid>
            <InlineWidget 
            styles={{
              height: '1000px'
            }}
            
            url="https://calendly.com/ryan365apps/contacts-manager-demo-beta-program-introduction" />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default NewsletterSection;
