import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "../styles/styles.module.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import StarIcon from "@mui/icons-material/Star";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    // backgroundColor: "#33bfff",
  },
  text1: {
    color: "#fff",
    // fontWeight: 600,
    fontSize: "44px",
    // textTransform: "uppercase",
  },
  text2: {
    color: "#fff",
    fontWeight: 200,
    fontSize: "24px",
    lineHeight: 1.2,
  },
  text3: {
    color: "#fff",
    fontWeight: 400,
    fontSize: "24px",
  },
  row: {
    margin: "30px 00px",

    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(6)}px`,
    },
  },
  featureitem: {
    // margin: "30px 10px",
  },
  figure: {
    // maxWidth: 300,
    // margin: "30px auto",
  },
  left: {
    position: "relative",
    left: "-300px",
  },
  image: {
    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    height: "auto",
    maxWidth: "100%",
    // minWidth: "800px",
  },
}));

function FeaturesDetailSection(props) {
  const classes = useStyles();

  const items = [
    {
      key: "xx0",
      title: "Sync your Outlook contacts with Teams",
      description:
        "Contacts Manager is Powered by the Microsoft Graph to " +
        "keep your contacts up to date and make your communication easy across your working life." +
        "",
      description2: "You don't have to do backflips just to start a group chat. Simply select your people and start." + "",
      list: [
        {
          key: "x0",
          value: "Sync Teams and Outlook contacts",
        },
        {
          key: "x1",
          value: "Quick to add and manage external contacts",
        },
        {
          key: "x2",
          value: "Global Address List integration",
        },
        {
          key: "x3",
          value: "Show Distribution lists in Teams",
        },
      ],
      styleType: "left",
      image: "images/CM6.webp",
    },
  ];
  //console.log(items);

  return (
    <Section
    id={props.name}
       bgColor={props.bgColor}
      size="large"
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        // maxWidth="md"
        
        className={classes.itemsContainer}
      >
        {items.map((item, index) => (
          <Grid className={classes.row} container={true} item={true} alignItems="center" spacing={8} key={index}>
            <Grid item={true} xs={12} md={12}>
              <Box
                textAlign={{
                  xs: "center",
                  md: "left",
                }}
              >
                <Typography className={classes.text1} variant="h2" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Typography className={classes.text2} variant="h6">
                  {item.description}
                </Typography>
                <Typography className={classes.text2} variant="h6">
                  {item.description2}
                </Typography>
            <Grid item={true} xs={12} md={12} 
            // className={item.styleType != "left" ? classes.left : null}
            >
              <figure
              // className={classes.figure}
              >
                <img src={item.image} alt={item.title} className={classes.image} />
              </figure>
            </Grid>
                <Grid
                  // className={classes.row}
                  container={true}
                  // spacing={4}
                  // direction="column"
                  key={item.key}
                >
                  <List aria-label="perks">
                    {item.list.map((listitem, index) => (
                      <ListItem>
                        <StarIcon />
                        <Typography className={classes.text3} variant="h6" id={listitem.key}>
                          {listitem.value}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Box>
            </Grid>

          </Grid>
        ))}
      </Container>
    </Section>
  );
}

export default FeaturesDetailSection;
