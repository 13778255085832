import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import newsletter from "./../util/newsletter.js";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { InlineWidget } from "react-calendly";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  inputBOx: {
    color: "#fff",
  },
}));

// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
// <script>
//   hbspt.forms.create({
// 	region: "na1",
// 	portalId: "7636048",
// 	formId: "58e1ce5d-f5e7-4c66-a71e-a570b4f59735"
// });
// </script>

const MyPage = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "7636048",
    formId: "58e1ce5d-f5e7-4c66-a71e-a570b4f59735",
    target: "#my-hubspot-form",
  });

  return (
    <div>
      <div id="my-hubspot-form"></div>
    </div>
  );
};
function ContactForm(props) {
  const classes = useStyles();
  let hbspt;

  const [subscribed, setSubscribed] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={6}>
          <Grid item={true} alignItems="center">
            <Typography variant="h4" gutterBottom={true} textAlign="center">
              Contact Us
            </Typography>
          </Grid>
          {subscribed === false && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <HubspotProvider>
                <MyPage />
              </HubspotProvider>
            </form>
          )}
          {subscribed === true && <div>{props.subscribedMessage}</div>}
        </Grid>
        <Grid item={true} xs={12} md={6} alignItems="center" style={{ justifyContent: "center" }}>
          <Grid item={true} alignItems="center">
            <Typography variant="h4" gutterBottom={true} textAlign="center">
              Book a demo
            </Typography>
          </Grid>
          <Grid item={true}>
            <InlineWidget
              styles={{
                height: "1000px",
              }}
              url="https://calendly.com/ryan365apps/contacts-manager-demo-beta-program-introduction"
            />
          </Grid>
        </Grid>
      </Grid>
      <a href="https://365apps.io">Visit 365apps.io</a>
    </>
  );
}

export default ContactForm;
