import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@mui/styles";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Msal2Provider, Msal2Config, Msal2PublicClientApplicationConfig } from "@microsoft/mgt-msal2-provider";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  button: {
    position: "relative",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
  icon: {
    position: "absolute",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    width: "1.5em",
    height: "1.5em",
    left: "0.5em",
    "& > img": {
      display: "block",
      width: "100%",
    },
  },
  lastUsedIndicator: {
    position: "absolute",
    top: "-6px",
    right: "-6px",
    fontSize: "13px",
    lineHeight: 1,
    textTransform: "initial",
    padding: "3px 5px",
    borderRadius: "7px",
    color: "white",
    backgroundColor: theme.palette.warning.main,
    opacity: 0.8,
  },
}));

// const Msal2Config = {
//   clientId: string;
//   scopes?: string[];
//   authority?: string;
//   redirectUri?: string;
//   loginType?: LoginType; // LoginType.Popup or LoginType.Redirect (redirect is default)
//   prompt?: PromptType; // PromptType.CONSENT, PromptType.LOGIN or PromptType.SELECT_ACCOUNT
//   sid?: string; // Session ID
//   loginHint?: string;
//   domainHint?: string;
//   options?: Configuration // msal-browser Configuration object
// }

function AuthSocial(props) {
  const classes = useStyles();

  const auth = useAuth();
  const [pending, setPending] = useState(null);
  const [lastUsed, setLastUsed] = useState(null);

  const providerDisplayNames = {
    microsoft: "Microsoft",
    // google: "Google",
    // facebook: "Facebook",
    // twitter: "Twitter",
    // github: "GitHub",
  };

  const onSigninWithProvider = (provider) => {
  setPending(provider);

  auth
  // .msftSignInPopup
    .signinWithProvider(provider)
    .then((user) => {
      // localStorage.setItem("lastUsedAuthProvider", provider);
      props.onAuth(user);
    })
    .catch((error) => {
      setPending(null);
      props.onError(error.message);
    });
  };

  function useIsSignedIn() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    useEffect(() => {
      const updateState = () => {
        const provider = Providers.globalProvider;

        setIsSignedIn(provider && provider.state === ProviderState.SignedIn);

        // //console.log('~ ProviderState', ProviderState);

        if (provider.state == 2) {
          window.location = "https://signup.contactsmanager.com";
        }
      };

      Providers.onProviderUpdated(updateState);

      updateState();

      return () => {
        Providers.removeProviderUpdatedListener(updateState);
      };
    }, []);

    return [isSignedIn];
  }

  const [isSignedIn] = useIsSignedIn();
  // Get value of last used auth provider
  useEffect(() => {
    if (props.showLastUsed) {
      const lastUsed = window.localStorage.getItem("lastUsedAuthProvider");
      if (lastUsed) {
        setLastUsed(lastUsed);
      }
    }
  }, [props.showLastUsed]);

  return (
    <div>
      <mgt-msal2-provider
        client-id="8c25b1c0-4e00-4623-9155-41c0df085ca5"
        login-type="popup"
        scopes="user.read,people.read"
        // redirect-uri="https://unisonwebsite-1.firebaseapp.com/__/auth/handler"
        // redirect-uri="http://localhost:3000/"
        redirect-uri="https://signup.contactsmanager.com/"
        authority="https://login.microsoftonline.com/dae9a39d-2bb1-4db6-aa2f-1ad3e244b7b3"
      ></mgt-msal2-provider>

      {isSignedIn && (
        <Box>
          <Typography
            variant="h6"
            sx={{
              mb: 2,
            }}
          >
            Signed In
          </Typography>
          <Typography>Redirecting...</Typography>
        </Box>
      )}

      <Button
        className={classes.button}
        variant="outlined"
        size="large"
        type="submit"
        onClick={() => {
          //console.log("sign out")
        }}
      >
        Sign Out
      </Button>

      {props.providers.map((provider) => (
        <Button
          className={classes.button}
          variant="outlined"
          size="large"
          type="submit"
          disabled={pending === provider}
          fullWidth={true}
          onClick={() => {
            onSigninWithProvider(provider);
          }}
          key={provider}
        >
          <div className={classes.icon}>
            <img src={`https://uploads.divjoy.com/icon-${provider}.svg`} alt={providerDisplayNames[provider]} />
          </div>

          {pending !== provider && (
            <span>
              {props.buttonText} with {providerDisplayNames[provider]}
            </span>
          )}

          {pending === provider && <CircularProgress size={28} />}

          {provider === lastUsed && <div className={classes.lastUsedIndicator}>Last used</div>}
        </Button>
      ))}
    </div>
  );
}

export default AuthSocial;
