import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import styles from "../styles/styles.module.scss";
import ComparisonTable from "../components/ComparisonTable";
import Typography from "@mui/material/Typography";
import HomeCTA1 from "../components/HomeCTA1";
import { makeStyles } from "@mui/styles";
import PricingEnterpriseSection from "./../components/PricingEnterpriseSection";
import PricingTeamSection from "./../components/PricingTeamSection";
import FaqSection from "./../components/FaqSection";
import Switch from "@mui/material/Switch";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import { useDarkMode } from "./../util/theme.js";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

/*

  uses the pricing Team Section only
  

*/

function PricingSection(props) {
  const [state, setState] = React.useState(false);
  const darkMode = useDarkMode();
  const theme = useTheme();
  const handleChange = (event) => {
    setState(!state);
    //console.log(state);
  };

  return (
    <div>
      <Section
        size={"medium"}
        className={darkMode.value ? styles.mainHeroBackgroundInverted : styles.mainHeroBackground}
      >
        <Container>
          <Grid direction="row" container>
            <Grid
              item
              style={{ display: "flex", justifyContent: "flex-end" }}
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              xs={5}
            >
              <Typography variant="h5" component="h2"
                 style={{
                  fontWeight: !state ? "bold" : "normal",
                  color: !state ? theme.palette.primary.main : theme.palette.primary.standard,
                }}
              >
                Pricing for Teams
              </Typography>
            </Grid>

            <Grid item alignItems="center" xs={2} style={{ display: "flex", justifyContent: "center" }}>
              <Switch checked={state} onChange={handleChange} />
            </Grid>
            <Grid
              item
              alignItems="center"
              justify="flex-start"
              xs={5}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Typography variant="h5" component="h2"
              style={{
                fontWeight: !state ? "normal" : "bold",
                color: !state ? theme.palette.primary.standard : theme.palette.primary.main,
              }}
              >
                Pricing for Organizations
              </Typography>
            </Grid>
          </Grid>
        </Container>
        {!state ? (
          <PricingTeamSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            title="Teams"
          ></PricingTeamSection>
        ) : (
          <PricingEnterpriseSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            title="Organisation"
          ></PricingEnterpriseSection>
        )}
        <ComparisonTable bgColor="light" 
        size="normal" bgImage="" bgImageOpacity={1} title="" subtitle="" />
       
        <FaqSection
        bgColor="default" 
        >
        </FaqSection>
        
        <HomeCTA1
        bgColor="light"
        size="medium"
        // bgImage="/src/styles/images/bgCirc.svg"
        bgImageOpacity={1}
        Buttontitle="Download for Teams"
        subtitle="Get an Extended 60-day trial for a limited time only"
        subtitle2="View in the Teams app store"
        subtitle3="View in AppSource"
        image="images/ms_appsource.png"
        // image="images/CM1.webp"
        // buttonText="try it out"
        // buttonColor="secondary"
        // buttonPath="/pricing"
      />
      </Section>
    </div>
  );
}

export default PricingSection;
