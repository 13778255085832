import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { CtaButton } from "../components/CtaSection";

import styles from "../styles/styles.module.scss";

function LandingPage2(props) {
  const useStyles = makeStyles((theme) => ({
    image: {
      margin: "0 auto",
      maxWidth: 1170,
      display: "block",
      height: "auto",
      width: "100%",

      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    },
    mainRow: {
      margin: "1vw",
      // padding: "10vw",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Container
        sx={{
          padding: "0",
          backgroundColor: "#e3f2fd",
          paddingTop: "4vw",
          paddingBottom: "10vw",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Grid item={true}>
              <Typography variant="h2" component="h1" gutterBottom={true}>
                Microsoft Teams Contacts Manager: Agenda Views for Effortless Scheduling{" "}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant="h4">
                Simplify Scheduling with Contacts Manager Agenda Views in Microsoft Teams{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              {" "}
              <Button
                target="_blank"
                variant="contained"
                sx={{
                  fontSize: "1.0rem",
                }}
                href={"https://teams.microsoft.com/l/app/29a37c62-cef0-4d57-ae0b-d5af9cacdd01?source=downloads-view"}
              >
                Add to Teams
              </Button>
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              {" "}
              <figure>
                <img src="images/CM1.webp" alt="illustration" className={classes.image} />
              </figure>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item={true}>
                <Box>
                  <Grid
                    // className={classes.row}
                    container={true}
                    // spacing={4}
                    direction="column"
                  >
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        Experience the fastest and most convenient way to find time with your colleagues in Microsoft
                        Teams using Contacts Manager Agenda Views. Say goodbye to time-consuming calendar searches and
                        scheduling confusion.{" "}
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                      ▪ Streamlined Scheduling with Microsoft Teams Status: No need to ask people if they're free or
                        sift through Outlook calendars – just click to see their availability.{" "}
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                      ▪ Easy Team Scheduling: Select the team members you need to meet with and click the agenda button.
                        It's that simple to find a time that works for everyone.
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                      ▪ Integrated Free/Busy Schedules: Access Outlook free/busy information for all your colleagues,
                        ensuring you always know when people are available for meetings.
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "2vw",
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          Contacts Manager Agenda Views seamlessly integrates with your Outlook calendar, contacts list,
                          and Office 365 contacts for an efficient and user-friendly scheduling experience. Start using
                          Contacts Manager today and revolutionize the way you plan and schedule meetings in Microsoft
                          Teams.
                        </Typography>
                      </Box>

                      {/* <Typography  variant="h4" gutterBottom>
                    Saves Time
                  </Typography>
                  <Typography  variant="h5" gutterBottom>
                    Great for anyone taking calls to quickly find free people in specific departments, ensuring a smooth
                    call transfer experience for both your customers and your team.
                  </Typography> */}
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid item={true} xs={6} md={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default LandingPage2;
