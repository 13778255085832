import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { CtaButton } from "../components/CtaSection";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Link } from "./../util/router.js";

import { useTheme } from "@mui/styles";

function HomeCTA1(props) {
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    // Increase <Container> padding so it's
    // at least half of <Grid> spacing to
    // avoid horizontal scroll on mobile.
    // See https://material-ui.com/components/grid/#negative-margin

    container: {
      padding: `0 ${theme.spacing(3)}px`,
    },
    image: {
      maxWidth: 1170,
      display: "block",
      height: "150px",

      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    },
    imageContainer: {
      borderRadius: "0px",
      border: "0px  solid",
    },

    sectionHeader: {
      padding: "10px",
      marginBottom: "20px",
      border: "0px  solid",
    },
    sectionHeader2: {
      margin: "0 auto",
      width: "60%",
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      width: 300,
      height: 60,
      fontSize: "20px",
      color: "#fff",
      fontWeight: 400,
      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        opacity: [0.9, 0.8, 0.7],
        boxShadow: "rgb(0 0 0 / 15%) 0px 1px 1px 1px, rgb(101 111 119 / 35%) 0px 20px 20px -12px",
      },
    },
    subtitle: {
      fontSize: "18px",
      justifyContent: "center",
    },
    comingsoonbannerdiv: {
      backgroundColor: theme.palette.primary.main,
      height: "30px",
      width: "140px",
      transform: "rotate(320deg)",
      position: "relative",
      top: "61px",
      left: "-16px",
    },
    comingsoonbanner: {
      color: "#fff",
      fontSize: "20px",
    },
  }));

  const classes = useStyles();

  return (
    <Section
      id={props.name}
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container style={{ display: "flex", justifyContent: "center", border: "0px red solid" }}>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction="column"
              style={{ display: "flex", justifyContent: "flex-start", border: "0px red solid" }}
            >
              <Grid
                item
                // xs={12} lg={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBlockStart: "5em",
                  // margin-block-end: 1em;
                  // margin-inline-start: 40px;
                  // margin-inline-end: 40px;
                }}
              >
                <CtaButton></CtaButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", border: "px red solid" }}>
            <figure>
              <div className={classes.imageContainer}>
            
              <Button 
              className={classes.customButton}              
              target="_blank"
              href={"https://appsource.microsoft.com/en-gb/product/office/WA200004175?tab=Overview "}
                >
                  <img src={props.image} alt="illustration" className={classes.image} />
                </Button>
              </div>
            </figure>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default HomeCTA1;
