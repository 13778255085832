import React from "react";
import NewsletterSection from "../components/NewsletterSection";
import Hero from "../components/Hero";

function WaitListPage(props) {
  return (
    <div
    id="mainContainer"
    // className={styles.mainContainer}
    // style={{
    //   backgroundImage: "url('/images/bgCirc.svg')",
    //   "background-position": "initial",
    //   "background-repeat": "space",
    //   "background-size": "contain",
    // }}
  >
    <NewsletterSection
    // bgColor="primary"
        size="large"
        bgImage=""
        bgImageOpacity={1}
        title="Join our waiting list"
        // subtitle="Receive our latest articles and feature updates"
        buttonText="Get Early Access"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed! Look out for updates from 365Apps"
    
    />
    <Hero
    // bgColor="default"
    // size="medium"
    bgImage="/src/styles/images/bgCirc.svg"
    bgImageOpacity={1}
    title="Better Contacts Management in Teams"
    subtitle="Use Contacts Manager to organize your contacts the way you want. Be more productive when working in Teams"
    // subtitle2="Manage your contacts and work with your team mates from a single, central hub"
    // subtitle3="Unison:People"
    image="images/CM1.webp"
  
  />
    </div>
  );
}

export default WaitListPage;
