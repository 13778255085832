import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: { 
    width: theme.spacing(15),
    height: theme.spacing(15),
    borderRadius: "50%",
  },
  text1: {
    color: "#fff",
    // fontWeight: 600,
    // fontSize: "44px",
    // textTransform: "uppercase",
  },
}));

function TeamBiosSection(props) {
  const classes = useStyles();

  const items = [
    {
      avatar: "images/Avatar.png",
      name: "persona",
      role: "",
      bio: "Hi, I'm Ryan, a Microsoft 365 consultant, and the founder of 365Apps, the company behind Contacts Manager",
    }
  ]
    // {
    //   avatar: "https://uploads.divjoy.com/pravatar-150x-35.jpeg",
    //   name: "Lisa Zinn",
    //   role: "Software Engineer",
    //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
    // },
    // {
    //   avatar: "https://uploads.divjoy.com/pravatar-150x-16.jpeg",
    //   name: "Diana Low",
    //   role: "Designer",
    //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
    // },
  

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      id="TrustSection" 
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true}    textAlign="center" justifyContent="center"  spacing={4}>
{/*           
            <Grid item={true} xs={12} sm={6} md={4} >
              <Card className={classes.card}>
                <CardContent>
                  <Box className={classes.avatarWrapper}>
                   
                     
                      <img src="images/Avatar.png" alt={items.name} className={classes.avatar} />
                    
                  </Box>
                  <Box textAlign="center" pt={3}>
                    <Typography variant="h1" component="p">
                      {items.name}
                    </Typography>
                    <Typography                    
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {items.role}
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="h5" component="p">
                        {items[0].bio}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid> */}
          
          
          <Grid item={true} xs={12} sm={6} md={8} >
            {/* <Card className={classes.card} >
              <CardContent> */}
                <Box className={classes.avatarWrapper}>
                
                </Box>
                <Box textAlign="left" pt={3}>
                  <Typography variant="h4" component="p" className={classes.text1}>
                  Teams is open all day long.... that's hours of wasted clicks
                  </Typography>
                  <Typography
                    variant="body1"
                    
                    component="p"
                    className={classes.text1}>
                    We all find contacts management in Teams less than ideal. 
                    It's easy to waste your time on lengthy, repetitive tasks 
                    when trying to collaborate.                    
                    It's annoying, confusing, and unproductive
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1" component="p" className={classes.text1}>
                      That's why Contacts Manager exists - so you can stop messing around with contacts, 
                      and get back to the important work
                    </Typography>
                  </Box>
                </Box>
              {/* </CardContent>
            </Card> */}
          </Grid>
        
      </Grid>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
