import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import SectionHeader from "./SectionHeader";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    borderTop: "1px solid #dadada",
    borderBottom: "1px solid #dadada",
  },
  itemRow: {
    backgroundColor: "#fff",
    borderRight: "1px solid #dadada",
    borderLeft: "1px solid #dadada",
    borderTop: "1px solid #dadada",

    "&:last-child": {
      borderBottom: "1px solid #dadada",
    },
  },
  item: {
    "&:not(:last-child)": {
      borderRight: "1px solid #dadada",
    },
    fontWeight: 600,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
}));

function ComparisonTable(props) {
  const classes = useStyles();

  const items = [
    {
      name: "Outlook Integration & Sync",
      check: true,
      check1: false,
    },
    {
      name: "Global Address list",
      check: true,
      check1: false,
    },   
    {
      name: "Sorting Contacts",
      check: true,
      check1: false,
    },
    {
      name: "Customizable views",
      check: true,
      check1: false,
    },
    {
      name: "Filtering",
      check: true,
      check1: false,
    },
    {
      name: "Multi Select",
      check: true,
      check1: false,
    },
    {
      name: "Free/Busy Views",
      check: true,
      check1: false,
    },
  ];

  return (
    <Section id={props.name} bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Box textAlign="center">
        <SectionHeader title={props.title} subtitle={props.subtitle} size={4} textAlign="center" />
        <Container
        // className={classes.itemsContainer}
        >
          <Paper>
            <Grid className={classes.itemRow} container item>
              <Grid
                className={classes.item}
                item
                xs={6}
                md={6}
                lg={6}
                sx={{
                  padding: 2,
                }}
              >
                <Typography
                  variant="body"
                  sx={
                    {
                      // textTransform: "uppercase",
                      // fontWeight: 600,
                    }
                  }
                >
                  Contacts Manager vs standard contacts in Teams
                </Typography>
              </Grid>

              <Grid
                className={classes.item}
                item
                xs={3}
                md={3}
                lg={3}
                sx={{
                  // padding: 2,
                  mt:3
                }}
              >
                <Typography
                  sx={{
                    // textTransform: "uppercase",

                    fontWeight: 600,
                  }}
                >
                  Contacts Manager
                </Typography>
              </Grid>

              <Grid
                className={classes.item}
                item
                xs={3}
                md={3}
                lg={3}
                sx={{
                  // padding: 2,
                  mt:3
                }}
              >
                <Typography
                  sx={{
                    // textTransform: "uppercase",

                    fontWeight: 600,
                    // color: "#bababa",
                  }}
                >
                  Teams
                </Typography>
              </Grid>
            </Grid>
            {items.map((item, index) => (
              <Grid className={classes.itemRow} container key={1 + index} item sx={{}}>
                <Grid
                  className={classes.item}
                  item
                  xs={6}
                  md={6}
                  lg={6}
                  sx={{
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{
                      // fontSize: "24px",
                      fontWeight: 600,
                    }}
                    variant="body"
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid
                  className={classes.item}
                  item
                  xs={3}
                  md={3}
                  lg={3}
                  sx={{
                    padding: 2,
                    // backgroundColor:"#fb356920",
                    backgroundColor: "secondary",
                  }}
                >
                  <CheckIcon
                    sx={{
                      fontSize: "34px",
                      color: "#56fb42",
                    }}
                  />
                </Grid>

                <Grid
                  className={classes.item}
                  item
                  xs={3}
                  md={3}
                  lg={3}
                  sx={{
                    padding: 2,
                    // borderLeft:"1px red solid",
                  }}
                >
                  <ClearIcon
                    sx={{
                      fontSize: "34px",
                      color: "#bababa",
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Container>
      </Box>
    </Section>
  );
}

export default ComparisonTable;
