import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";

import FormControlLabel from "@mui/material/FormControlLabel";

import { CtaButton } from "../components/CtaSection";
import Button from "@mui/material/Button";
import { Link } from "../util/router.js";
import { useAuth } from "../util/auth.js";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import { useTheme } from "@mui/styles";

function PricingTeamSection(props) {
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    card: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      backgroundColor: "#fff",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: theme.spacing(1),
    },
    price: {
      display: "flex",
      alignItems: "baseline",
      margin: "30px",
    },
    listItem: {
      paddingTop: 2,
      paddingBottom: 2,
    },
    perkIcon: {
      minWidth: 34,
      color: theme.palette.success.main,
    },
    offer: {},
    text: {
      fontSize: "3.5rem",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("lg")]: {
        fontSize: "1.5rem",
      },
    },
    text2: {
      fontSize: "1.5rem",
      [theme.breakpoints.down("lg")]: {
        fontSize: "1.2rem",
      },
    },
  }));

  const items = [
    // {
    //   key: 0,
    //   users: "1 Seat",
    //   basePrice: 20,
    //   userPrice: 20,
    //   name: "Individual",
    // },
    {
      key: 1,
      users: "Up to 5 Users",
      // basePrice: 32.5,
      userPrice: 3,
      name: "Team",
    },
    {
      key: 2,
      users: "Up to 25 Users",
      // basePrice: "137.50",
      userPrice: "2.75",
      name: "Group",
    },
    {
      key: 3,
      users: "Up to 50 Users",
      // basePrice: 225,
      userPrice: 2.5,
      name: "Department",
    },
  ];

  const classes = useStyles();

  const auth = useAuth();

  const [annualState, setAnnualState] = React.useState(false);
  //console.log("Annual", annualState);

  // const darkMode = useDarkMode();

  const [switchstate, setswitchState] = React.useState(false);

  const handleChange = (event) => {
    if (annualState) {
      //console.log("Annual", annualState);
    } else {
      //console.log("Annual", annualState);
    }

    setAnnualState(!annualState);
  };

  const [value1, setValue1] = React.useState();
  const [sliderval, setsliderval] = React.useState();
  const [userNumbers1, setuserNumbers1] = React.useState();
  const [basePrice, setbasePrice] = React.useState();

  return (
    <Section size={"medium"}>
      <Container>
        <Grid direction="column" container={true} spacing={1}>
          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h3" component="h2">
              Pricing Plans for Individuals and Teams
            </Typography>
          </Grid>

          <Grid
            container={true}
            direction="row"
            sx={{
              mt: 5,
              mb: 5,
              // margin:5
            }}
          >
            <Grid
              item
              alignItems="center"
              justify="flex-end"
              xs={5}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography
                variant="h4"
                // component="h2"
                style={{
                  fontWeight: annualState ? "normal" : "bold",
                  color: annualState ? theme.palette.primary.standard : theme.palette.primary.main,
                }}
              >
                Monthly
              </Typography>
            </Grid>

            <Grid alignItems="center" xs={2} style={{ display: "flex", justifyContent: "center" }}>
              <Switch sx={{}} checked={annualState} onChange={handleChange} />
            </Grid>
            <Grid
              style={{ display: "flex", justifyContent: "flex-start" }}
              item
              alignItems="center"
              justify="flex-start"
              xs={5}
            >
              <Typography
                variant="h4"
                sx={{
                  ml: 2,
                }}
                style={{
                  fontWeight: annualState ? "bold" : "normal",
                  color: annualState ? theme.palette.primary.main : theme.palette.primary.standard,
                }}
                // component="h2"
              >
                Annual{" "}
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  ml: 2,
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                (Save 20%)
              </Typography>
            </Grid>
          </Grid>



          {/*Cards */}

          <Grid
            direction="row"
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
              // , maxWidth:"500px"
            }}
          >

            {items.map((item, index) => (
              <Grid item={true} xs={10} md={3}>
                <Paper elevation={3}>
                  {/* Card*/}
                  <Card
                    className={classes.card}
                    variant="outlined"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      borderTop: "8px #fb3569 solid",
                    }}
                  >
                    <CardContent className={classes.cardContent}>
                      <Grid
                        direction="column"
                        container={true}
                        spacing={2}
                      >
                        {/* Card Title*/}
                        <Grid item={true} style={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 800,
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        {/* Card Subtitle*/}
                        <Grid item={true} style={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="h5"
                            sx={{
                                mt:1,
                              fontWeight: 400,
                            }}
                          >
                            {item.users}
                          </Typography>
                        </Grid>

                        {/* Per User Price Line*/}
                        <Grid
                          container
                          direction="row"
                          sx={
                            {
                               mt: 1,
                            }
                          }
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Grid
                            item={true}
                            style={{
                              // display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                // fontSize: "30px",
                                color: theme.palette.primary.main,
                                fontWeight: 800,
                              }}
                            >
                              {"$ "} {annualState ? item.userPrice * 10 : item.userPrice}{"  "} 
                            </Typography>
                          </Grid>
                          <Grid item={true}>
                            <Typography variant="h5" sx={{
                                paddingLeft: "10px",

                            }}>
                             { "/ User "}{annualState ? "/ Year" : "/ Month"}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* Per Month Total Price Line*/}
                        <Grid
                          container
                          direction="row"
                          sx={
                            {
                               mt: 1,
                            }
                          }
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Grid item={true}>
                            <Typography
                              variant="h5"
                              sx={
                                {
                                  // fontSize: "30px",
                                }
                              }
                            >
                              {/* {"$ "} {annualState ? item.basePrice * 10 : item.basePrice} */}
                            </Typography>
                          </Grid>
                          <Grid item={true}>
                            <Typography
                              variant="h5"
                              sx={
                                {
                                  // fontSize: "30px",
                                }
                              }
                            >
                              {/* {annualState ? "/ Year" : "/ Month"} */}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* CTA Button*/}
                        <Grid
                          item={true}
                          style={{ display: "flex", justifyContent: "center", marginTop: annualState ? 16 : 16 }}
                        >
                          <CtaButton size="1rem"></CtaButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingTeamSection;
