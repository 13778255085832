import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    // Add bottom margin if element below
    "&:not(:last-child)": {
      marginBottom: "2rem",
    },
  },
  subtitle: {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    //  maxWidth: 400,
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: "grid",
  },
}));

function SectionHeader(props) {
  const classes = useStyles();

  const { subtitle,subtitle2,subtitle3, title, size, className, ...otherProps } = props;

  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <Box
      component="header"
      className={classes.root + (props.className ? ` ${props.className}` : "")}
      {...otherProps}
    >
      {title && (
        <Typography
          className={classes.subtitle}
          variant={`h${size}`}
          gutterBottom={props.subtitle ? true : false}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <Typography variant="h5" 
        align="center"
        className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {subtitle2 && (
        <Typography 
        variant="subtitle1"
        

        align="center"
        className={classes.subtitle}>
          {subtitle2}
        </Typography>
      )}
      {subtitle3 && (
        <Typography 
        variant="h4"
        
        align="center"
        className={classes.subtitle}>
          {subtitle3}
        </Typography>
      )}
    </Box>
  );
}

export default SectionHeader;
