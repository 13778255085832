import React from "react";

import Hero from "../components/Hero";
import HomeCTA1 from "../components/HomeCTA1";
import Quad from "../components/Quad";
import Example from "../components/Example";
import Example2 from "../components/Example2";
import HomeSection1 from "../components/HomeSection1";
import FeaturesSection from "../components/FeaturesSection";
import FeaturesDetailSection from "../components/FeaturesDetailSection";
import TrustSection from "../components/TrustSection";
import NewsletterSection from "../components/NewsletterSection";
import styles from "../styles/styles.module.scss";
import ComparisonTable from "../components/ComparisonTable";

function HomePage(props) {
  return (
    <div
      id="mainContainer"
      className={styles.mainContainer}
      // style={{
      //   backgroundImage: "url('/images/bgCirc.svg')",
      //   "background-position": "initial",
      //   "background-repeat": "space",
      //   "background-size": "contain",
      // }}
    >
      <Hero
      name="Hero"
        // bgColor="default"
        // size="medium"
        bgImage="/src/styles/images/bgCirc.svg"
        bgImageOpacity={1}
        title="Better Contacts Management in Teams"
        subtitle="Use Contacts Manager to organize your contacts the way you want. Be more productive when working in Teams"
        // subtitle2="Manage your contacts and work with your team mates from a single, central hub"
        // subtitle3="Unison:People"
        image="images/CM1.webp"
      />
      <HomeCTA1
        name="HomeCTA"
        bgColor="light"
        size="medium"
        // bgImage="/src/styles/images/bgCirc.svg"
        bgImageOpacity={1}
        Buttontitle="Download for Teams"
        subtitle="Get an Extended 60-day trial for a limited time only"
        subtitle2="View in the Teams app store"
        subtitle3="View in AppSource"
        image="images/ms_appsource.png"
        // image="images/CM1.webp"
        // buttonText="try it out"
        // buttonColor="secondary"
        // buttonPath="/pricing"
      />
      
      <TrustSection
        name="TrustSection"
      bgColor="secondary"></TrustSection>

      <FeaturesSection
      name="FeaturesSection"
        bgColor="secondary"
        // size="large"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="Easily organize contacts & colleagues"
      />

      
      <FeaturesDetailSection
            name="FeaturesDetailSection"

        bgColor="secondary"
        size="medium"
        bgImage=""
        // bgImageOpacity={1}
        // title="Features"
        // subtitle="Easily organize contacts & colleagues"
      />

      <Quad
                  name="Quad"

        bgColor="light"
        // bgColor="default"
        // size="medium"
        // // bgImage="/src/styles/images/bgCirc.svg"
        // bgImageOpacity={1}
        // Buttontitle="Download for Teams"
        // subtitle="Get an Extended 60-day trial for a limited time only"
        // subtitle2="View in the Teams app store"
        // subtitle3="View in AppSource"
        // image="images/ms_appsource.png"
        // image="images/CM1.webp"
        // buttonText="try it out"
        // buttonColor="secondary"
        // buttonPath="/pricing"
      />

      <Example
        name="Example"
      // bgColor="default"
      // size="medium"
      // // bgImage="/src/styles/images/bgCirc.svg"
      // // bgImageOpacity={1}
      // Buttontitle="Download for Teams"
      // subtitle="Get an Extended 60-day trial for a limited time only"
      // subtitle2="View in the Teams app store"
      // subtitle3="View in AppSource"
      // image="images/CM1.webp"
      // image="images/CM1.webp"
      // buttonText="try it out"
      // buttonColor="secondary"
      // buttonPath="/pricing"
      />

      {/* <Example2 
              name="Example2"

      // bgColor="default"
      // size="medium"
      // // bgImage="/src/styles/images/bgCirc.svg"
      // bgImageOpacity={1}
      // Buttontitle="Download for Teams"
      // subtitle="Get an Extended 60-day trial for a limited time only"
      // subtitle2="View in the Teams app store"
      // subtitle3="View in AppSource"
      // image="images/CM1.webp"
      // image="images/CM1.webp"
      // buttonText="try it out"
      // buttonColor="secondary"
      // buttonPath="/pricing"
       /> */}
      <ComparisonTable
              name="ComparisonTable"

      bgColor="light" size="normal" bgImage="" bgImageOpacity={1} title="" subtitle="" />

      <HomeCTA1
        name="HomeCTA2"
        bgColor="default"
        size="medium"
        // bgImage="/src/styles/images/bgCirc.svg"
        bgImageOpacity={1}
        Buttontitle="Download for Teams"
        subtitle="Get an Extended 60-day trial for a limited time only"
        subtitle2="View in the Teams app store"
        subtitle3="View in AppSource"
        image="images/ms_appsource.png"
        // image="images/CM1.webp"
        // buttonText="try it out"
        // buttonColor="secondary"
        // buttonPath="/pricing"
      />

      {/* <NewsletterSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Join our waiting list"
        // subtitle="Receive our latest articles and feature updates"
        buttonText="Get Early Access"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed! Look out for updates from 365Apps"
      /> */}
    </div>
  );
}

export default HomePage;
