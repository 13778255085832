import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { CtaButton } from "../components/CtaSection";

import styles from "../styles/styles.module.scss";

function LandingPage1(props) {
  const useStyles = makeStyles((theme) => ({
    image: {
      margin: "0 auto",
      maxWidth: 1170,
      display: "block",
      height: "auto",
      width: "100%",

      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    },
    mainRow: {
      margin: "1vw",
      // padding: "10vw",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Container
        sx={{
          padding: "0",
          backgroundColor: "#e3f2fd",
          paddingTop: "4vw",
          paddingBottom: "10vw",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Grid item={true}>
              <Typography variant="h2" component="h1" gutterBottom={true}>
                Microsoft Teams Contacts Manager: Custom Lists for Rapid Call Transfers{" "}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant="h4">
                Efficient Call Management with Contacts Manager Custom Lists in Microsoft Teams
              </Typography>
            </Grid>
          </Grid>

          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              <Button
                target="_blank"
                variant="contained"
                sx={{
                  fontSize: "1.0rem",
                }}
                href={"https://teams.microsoft.com/l/app/29a37c62-cef0-4d57-ae0b-d5af9cacdd01?source=downloads-view"}
              >
                Add to Teams
              </Button>
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              <figure>
                <img src="images/CM1.webp" alt="illustration" className={classes.image} />
              </figure>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item={true}>
                <Box>
                  <Grid
                    // className={classes.row}
                    container={true}
                    // spacing={4}
                    direction="column"
                  >
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        Find available colleagues in Microsoft Teams with lightning speed using Contacts Manager Custom
                        Lists. Ensure seamless call transfers and deliver exceptional customer experiences.
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                        ▪ Instantly Locate Free Team Members: Easily transfer calls to the right team using custom lists
                        that show who's available, improving call handling efficiency and minimizing wait times.
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                        ▪ Maximize Customer Satisfaction: Streamline your call management process to ensure your
                        customers receive prompt assistance and a high level of satisfaction.
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                        ▪ Real-Time Presence Status: Create custom lists that display real-time presence status, so you
                        always know who's free to take a call and can make informed decisions on the fly.
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "2vw",
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          Upgrade your call management in Microsoft Teams with Contacts Manager Custom Lists. Get
                          started today and enjoy a more efficient and effective call handling process for your team.
                        </Typography>
                      </Box>
                      {/* <Typography  variant="h4" gutterBottom>
                    Saves Time
                  </Typography>
                  <Typography  variant="h5" gutterBottom>
                    Great for anyone taking calls to quickly find free people in specific departments, ensuring a smooth
                    call transfer experience for both your customers and your team.
                  </Typography> */}
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid item={true} xs={6} md={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default LandingPage1;
