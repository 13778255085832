import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import newsletter from "./../util/newsletter.js";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  inputBOx: {
    color: "#fff",
  },
}));


// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/shell.js"></script>
// <script>
//   hbspt.forms.create({
// 	region: "na1",
// 	portalId: "7636048",
// 	formId: "cdc76f1f-fc21-4c42-ae61-257a92da048f"
// });
// </script>


const MyPage = () => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "7636048",
    formId: "cdc76f1f-fc21-4c42-ae61-257a92da048f",
    target: "#my-hubspot-form",
  });

  return (
    <div>
     
      <div id="my-hubspot-form"></div>
    </div>
  );
};
function Newsletter(props) {
  const classes = useStyles();
  let hbspt;

  const [subscribed, setSubscribed] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  // const script = document.createElement("script");

  // script.src = "https://js.hsforms.net/forms/v2.js";
  // script.async = true;
  // document.body.appendChild(script);

  // useEffect(() => {
  //   window.hbspt.forms.create({
  //     region: "na1",
  //     portalId: "7636048",
  //     formId: "f8beef6c-4ee1-45db-ae7d-616fa3a5bb84",
  //   });
  // }, []);

  return (
    <>
      {subscribed === false && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid >
            {/* <Grid item={true} xs={true}> */}
              <HubspotProvider>
                <MyPage />
              </HubspotProvider>
              
            
          </Grid>
        </form>
      )}

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default Newsletter;
