import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { CtaButton } from "../components/CtaSection";

import styles from "../styles/styles.module.scss";

function LandingPage3(props) {
  const useStyles = makeStyles((theme) => ({
    image: {
      margin: "0 auto",
      maxWidth: 1170,
      display: "block",
      height: "auto",
      width: "100%",

      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    },
    mainRow: {
      margin: "1vw",
      // padding: "10vw",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Container
        sx={{
          padding: "0",
          backgroundColor: "#e3f2fd",
          paddingTop: "4vw",
          paddingBottom: "10vw",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Grid item={true}>
              <Typography variant="h2" component="h1" gutterBottom={true}>
                Contacts Manager for Microsoft Teams: Work with Contacts Your Way{" "}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant="h4">Unlock the Power of Microsoft Teams Contacts with Contacts Manager </Typography>
            </Grid>
          </Grid>

          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              <figure>
                <img src="images/CM1.webp" alt="illustration" className={classes.image} />
              </figure>
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              <Button
                target="_blank"
                variant="contained"
                sx={{
                  fontSize: "1.0rem",
                }}
                href={"https://teams.microsoft.com/l/app/29a37c62-cef0-4d57-ae0b-d5af9cacdd01?source=downloads-view"}
              >
                Add to Teams
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item={true}>
                <Box>
                  <Grid
                    // className={classes.row}
                    container={true}
                    // spacing={4}
                    direction="column"
                  >
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        Discover the ultimate flexibility and control in managing your contacts with the comprehensive
                        Contacts Manager app for Microsoft Teams. Tailor your experience to meet your needs and optimize
                        your workflow within the Microsoft Teams environment.
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                      ▪ Customizable Microsoft Teams Contacts List: Personalize your views, fields, and more to work the
                        way you want. Easily access your Microsoft Teams calling and phone options within your tailored
                        contact list.{" "}
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                      ▪ Organize with Categories, Tags, and Filters: Maximize your productivity by using categories and
                        tags for efficient organization. Find the right contact quickly with advanced filtering and
                        sorting options.
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                      ▪ Seamless Integration with Microsoft Teams App: Stay connected and work efficiently whether
                        you're using Microsoft Teams online or on the go with the Microsoft Teams app. Experience
                        seamless integration and management of your contacts, calls, and status updates.
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "2vw",
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          Elevate your Microsoft Teams experience with the Contacts Manager app and gain the ultimate
                          flexibility in managing your contacts. Download and try it now to revolutionize the way you
                          work with contacts in Microsoft Teams.
                        </Typography>
                      </Box>
                      {/* <Typography  variant="h4" gutterBottom>
                    Saves Time
                  </Typography>
                  <Typography  variant="h5" gutterBottom>
                    Great for anyone taking calls to quickly find free people in specific departments, ensuring a smooth
                    call transfer experience for both your customers and your team.
                  </Typography> */}
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid item={true} xs={6} md={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default LandingPage3;
