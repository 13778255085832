import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: "#FFF",
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Is my data secure with Contacts Manager ? ",
      answer:
        "Absolutely. Your data stays in your Microsoft 365 tenant. We don't capture or store any data from the app. It’s made to keep communication private and safe, individually and across an organization. "        
    },
    {
      question: "Does Contacts Manager conform to my organization’s security protocols? ",
      answer:
        "We are happy to discuss any queries your IT department may have regarding security and data management. ",
    },
    {
      question: "How do we install Contacts Manager? ",
      answer:
        "You can install directly form the Teams App store. The app will appear in your Teams admin center where you it can be published to your organisation, or a subset of users. As the app requires permission to query the Microsoft "+
        "Graph on behalf of your users (using delegated permissions) consent will need to be given for these requests. This can be done within the admin center. ",
    },
    {
      question: "How do we use the Contacts Manager app?",
      answer: 'Help is provided via the built-in "Help" tour button. This shows users what each section is for and how it works',
    },
    {
      question: "What do I get with email support?",
      answer: "Full email support 5 days a week. In most cases, you can expect same-day answers to your questions. ",
    },
    {
      question: "Why is there such a long trial period? ",
      answer:
        "ContactsManager is a productivity app - we understand what it’s like to be under a time crunch! This extended trial period is a limited-time offer that gives you and your users extra time to evaluate ContactManager’s contributions to your daily work in Teams.  This is a limited-time extension to celebrate the launch of the open beta, so we recommend securing your extended trial as soon as possible. ",
    },
    {
      question: "How does the Contacts Manager work? ",
      answer:
        "Contacts Manager has been designed to be a lightweight companion app for Teams, powered by the Microsoft Graph, to provide an powerful user interface with data you already have access to. "+
        "The Microsoft Graph allows apps to query data from across the platform, on behalf of the user. This means the Graph decides what data is available to the user, and, therefore, to the app. "+
        "Contacts Manager queries the graph for data on people & contacts"
    },
  ];

  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container >
        <SectionHeader subtitle={props.subtitle} size={4} textAlign="center" />

        {items.map((item, index) => (
          <Accordion
          sx={{
            backgroundColor:"#fff"
          }}
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography
                variant="h5"
              sx={{
           
           

              }}
              >{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography
                variant="h6"
              sx={{
                
              }}
              
              >{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
