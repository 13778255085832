import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SectionHeader from "./SectionHeader";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "../styles/styles.module.scss";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import EventNote from "@mui/icons-material/EventNote";

import { useTheme } from "@mui/styles";


export default function Example(props) {
  const theme = useTheme();

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  text1: {
    padding: `0 ${theme.spacing(3)}px`,
    color: theme.palette.primary.standard,
  },
  text2: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    maxWidth: 1170,
    display: "block",
    height: "150px",
    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
  },
  imageContainer: {
    borderRadius: "0px",
    border: "0px  solid",
  },
  sectionHeader: {
    padding: "10px",
    marginBottom: "20px",
    border: "0px  solid",
  },
  sectionHeader2: {
    margin: "0 auto",
    
  },
  button: {
    width: 300,
    height: 60,
    fontSize: "20px",
    color: "#fff",
    fontWeight: 400,
    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      opacity: [0.9, 0.8, 0.7],
      boxShadow: "rgb(0 0 0 / 15%) 0px 1px 1px 1px, rgb(101 111 119 / 35%) 0px 20px 20px -12px",
    },
  },
  subtitle: {
    
    justifyContent: "center",
  },
  image: {
    margin: "0 auto",
    maxWidth: 1170,
    display: "block",
    height: "auto",
    width: "100%",
    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
  },
  imageContainer: {
    borderRadius: "0px",
    border: "0px  solid",
  },
}));


  const classes = useStyles();

  return (
    <Section 
    id={props.name}
    bgColor="#fb3569" 
    size={"large"}
    bgImage={props.bgImage} 
    bgImageOpacity={props.bgImageOpacity}
    
    >
      <Container>
        <Box textAlign="center">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs></Grid>
            <Grid item xs={8}>
              <Typography className={classes.text1} variant="h2" gutterBottom={true}>
                Move faster with Contacts Manager 
              </Typography>
              <Typography className={classes.text1} variant="h3" gutterBottom={true}>
                from 365Apps
              </Typography>

              <Typography className={classes.text1} variant="h4" gutterBottom={true}>
                Fully featured table layout with filters and sorting
              </Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
      </Container>
      <figure>
        <div className={classes.imageContainer}>
          <img src={"images/CM1.webp"} alt="illustration" className={classes.image} />
        </div>
      </figure>
    </Section>
  );
}

// export default HomeCTA2;
