import React from "react";
import { useTheme,responsiveFontSizes , createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import * as colors from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createLocalStorageStateHook } from "use-local-storage-state";

const themeConfig = {
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
    h1:{
      fontFamily: 'Montserrat'

    },
    button: {
      fontStyle: 'italic',
    },
  

    // fontFamily: [
    //   'Montserrat',
    //   'cursive',
    // ].join(','),
  },

  // Light theme
  light: {
    typography: {
      //fontFamily: '"Montserrat", "Roboto", sans-serif',
      fontFamily: '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
      // Uncomment to make button lowercase
      // button: { textTransform: "none" },
    },
    palette: {
      type: "light",
      primary: {
        // Use hue from colors or hex
        main: "#e91e63", //colors.pink["A200"],
        // Uncomment to specify light/dark
        // shades instead of automatically
        // calculating from above value.
        //light: "#4791db",
        //dark: "#115293",
        standard: "#111"
      },
      secondary: {
        main: "#00b0ff", //colors.lightBlue["900"],
      },
      background: {
        // Background for <body>
        // and <Section color="default">
        default: "#fff",
        // Background for elevated
        // components (<Card>, etc)
        paper: colors.grey["200"],
      },
      cta: {
        background: "#e91e63",
        color: "#fff",
      },
      productImages: {
        background: "#00b0ff",
        color: "#fff",
      },
      // mainHeroBackground: {
      //   "background: linear-gradient(0deg, #e91e63 30%, #fff 30%)",
      // },
      // mainHeroBackgroundInverted: {
      //   background: "linear-gradient(0deg, #00b0ff 30%, #424242 30%)",
      // },
      ctahover: {
        background: "#e91e63",
        color: "#fff",
      },
    },
  },

  // Dark theme
  // dark: {
  //   typography: {
  //     //fontFamily: '"Montserrat", "Roboto", sans-serif',
  //     fontFamily: '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
  //     // Uncomment to make button lowercase
  //     // button: { textTransform: "none" },
  //   },
  //   palette: {
  //     type: "dark",
  //     primary: {
  //       // Same as in light but we could
  //       // adjust color hue if needed
  //       main: "#33bfff",
  //       standard: "#fff"
  //     },
  //     secondary: {
  //       main: "#a31545", // colors.indigo["A400"],
  //     },
  //     background: {
  //       default: colors.grey["800"],
  //       paper: colors.grey["600"],
  //     },
  //   },
  // },

  // Values for both themes
  common: {
    typography: {
      //fontFamily: '"Montserrat", "Roboto", sans-serif',
      fontFamily: '"Montserrat", "Arial", sans-serif',
      // Uncomment to make button lowercase
      // button: { textTransform: "none" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          fontFamily: "Montserrat, cursive",
        },
      },
    
    },

  
    // Override component styles
    overrides: {
      h1: {
        fontFamily: "Montserrat, cursive",
      },
      alt: {
        fontFamily: "Roboto, sans-serif",
        
      },
      // Global styles
      MuiCssBaseline: {
        "@global": {
          "#root": {
            fontFamily: "Montserrat, cursive",
            // Flex column that is height
            // of viewport so that footer
            // can push self to bottom by
            // with auto margin-top
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // Prevent child elements from
            // shrinking when content
            // is taller than the screen
            // (quirk of flex parent)
            "& > *": {
              flexShrink: 0,
            },
          },
        },
      },
    },
  },
};

function getTheme(name) {
  // Create MUI theme from themeConfig
  let theme = createTheme({
    
    typography: {
      subtitle1: {
        fontSize: 12,
      },
      body1: {
        fontWeight: 500,
      },
      h1:{
        fontFamily: 'Montserrat'
  
      },
      button: {
        fontStyle: 'italic',
      },
      alt:{
        fontFamily: 'Roboto'
      }
    

  
      // fontFamily: [
      //   'Montserrat',
      //   'cursive',
      // ].join(','),
    },

    ...themeConfig[name],
    // Merge in common values
    // ...themeConfig.common,
    overrides: {
      // Merge overrides
      ...(themeConfig[name] && themeConfig[name].overrides),
      ...(themeConfig.common && themeConfig.common.overrides),
    },
  });
  theme = responsiveFontSizes(theme);

  return theme;
}

// Create a local storage hook for dark mode preference
const useDarkModeStorage = createLocalStorageStateHook("isDarkMode");

export const ThemeProvider = (props) => {
  // Get system dark mode preference
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  // Get stored dark mode preference
  let [isDarkModeStored, setIsDarkModeStored] = useDarkModeStorage();

  // Use stored dark mode with fallback to system preference
  const isDarkMode = isDarkModeStored === undefined ? prefersDarkMode : isDarkModeStored;

  // Get MUI theme object
  const themeName = isDarkMode ? "dark" : "light";
  const theme = getTheme(themeName);
  
  
  //console.log("~ theme", theme);

  // Add toggle function to theme object
  theme.palette.toggle = () => setIsDarkModeStored((value) => !value);

  return (
    <MuiThemeProvider theme={theme}>
      {/* Set global MUI styles */}
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
};

// Hook for detecting dark mode and toggling between light/dark
// More convenient than reading theme.palette.type from useTheme
export function useDarkMode() {
  // Get current Material UI theme
  const theme = useTheme();
  // Check if it's the dark theme
  const isDarkMode = theme.palette.type === "dark";
  // Return object containing dark mode value and toggle function
  return { value: isDarkMode, toggle: theme.palette.toggle };
}
