import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { CtaButton } from "../components/CtaSection";

import styles from "../styles/styles.module.scss";

function LandingPage3(props) {
  const useStyles = makeStyles((theme) => ({
    image: {
      margin: "0 auto",
      maxWidth: 1170,
      display: "block",
      height: "auto",
      width: "100%",

      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
    },
    mainRow: {
      margin: "1vw",
      // padding: "10vw",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Container
        sx={{
          padding: "0",
          backgroundColor: "#e3f2fd",
          paddingTop: "4vw",
          paddingBottom: "10vw",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Grid item={true}>
              <Typography variant="h2" component="h1" gutterBottom={true}>
                Microsoft Teams Mobile App: Contacts Manager for iOS and Android
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant="h4">
                Experience the Convenience of Contacts Manager on Microsoft Teams Mobile App
              </Typography>
            </Grid>
          </Grid>

          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              <Button
                target="_blank"
                variant="contained"
                sx={{
                  fontSize: "1.0rem",
                }}
                href={"https://teams.microsoft.com/l/app/29a37c62-cef0-4d57-ae0b-d5af9cacdd01?source=downloads-view"}
              >
                Add to Teams
              </Button>
            </Grid>
          </Grid>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid
              item={true}
              sx={{
                paddingTop: "3vw",
                paddingBottom: "3vw",
              }}
            >
              <figure>
                <img src="images/CM1.webp" alt="illustration" className={classes.image} />
              </figure>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item={true}>
                <Box>
                  <Grid
                    // className={classes.row}
                    container={true}
                    // spacing={4}
                    direction="column"
                  >
                    <Box>
                      <Typography variant="h4" gutterBottom>
                        Easily manage your contacts on the go with the Microsoft Teams mobile app & Contacts Manager,
                        available for both iOS and Android devices.
                      </Typography>
                      <Typography variant="h5" gutterBottom>
                        ▪ Download the Microsoft Teams app now to unlock a world of seamless communication and
                        collaboration.
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                        ▪ Make Calls Effortlessly: Initiate calls from your mobile device at the touch of a button,
                        using your PTSN calling plan or device to connect with your contacts.{" "}
                      </Typography>

                      <Typography variant="h5" gutterBottom>
                        ▪ Accessible on iOS and Android: Whether you're using an iPhone or an Android device, the
                        Microsoft Teams app brings the power of Contacts Manager to your fingertips.
                      </Typography>

                      <Box
                        sx={{
                          marginTop: "2vw",
                        }}
                      >
                        <Typography variant="h4" gutterBottom>
                          Microsoft Teams Download for Mobile: Get started today by downloading the Microsoft Teams app
                          for your iOS or Android device and experience seamless contact management on the go.{" "}
                        </Typography>
                      </Box>
                      {/* <Typography  variant="h4" gutterBottom>
                    Saves Time
                  </Typography>
                  <Typography  variant="h5" gutterBottom>
                    Great for anyone taking calls to quickly find free people in specific departments, ensuring a smooth
                    call transfer experience for both your customers and your team.
                  </Typography> */}
                    </Box>
                  </Grid>
                </Box>
              </Grid>

              <Grid item={true} xs={6} md={6}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default LandingPage3;
