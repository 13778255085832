import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin

  container: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  image: {
    margin: "0 auto",
    maxWidth: 1170,
    display: "block",
    height: "auto",
    width: "100%",

    boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
  },
  imageContainer: {
    borderRadius: "0px",
    border: "0px  solid",
  },

  sectionHeader: {
    padding: "10px",
    marginBottom: "20px",
    border: "0px  solid",
  },
  sectionHeader2: {
    margin: "0 auto",
    width: "60%",
  },
}));

function Hero(props) {
  const classes = useStyles();

  return (
    <Section bgColor={props.bgColor} size={props.size} bgImage={props.bgImage} bgImageOpacity={props.bgImageOpacity}>
      <Container>
        <Box textAlign="center">
          <Grid container spacing={4}>
            <Grid item>
              <Grid container direction="column" spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="h2" component="h1">
                    Better Contacts Management in Microsoft Teams
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h4" component="p">
                    Organize your contacts.
                  </Typography>
                  <Typography variant="h4" component="p">
                    Be more productive when working in Teams
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img src="images/CM1.webp" alt="illustration" className={classes.image} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Section>
  );
}

export default Hero;
