import React from "react";
import Section from "./Section";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "../styles/styles.module.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/styles";

function FeaturesSection(props) {
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    itemsContainer: {
      // backgroundColor: theme.palette.primary.main,
    },
    text1: {
      color: theme.palette.primary.standard,

      // textTransform: "uppercase",
    },
    text2: {
      color: "#646f79",
      fontWeight: 400,
      
    },
    text3: {
      color: "#646f79",
      fontWeight: 400,
      
    },
    row: {
      marginTop: `${theme.spacing(16)}`,

      // Reverse every other row
      "&:nth-of-type(even)": {
        // flexDirection: "row-reverse",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderWidth: "4px",
        "& img": {
          // border: "4px red solid",
          // position: "relative",
          // left: "-280px",
        },
      },

      "&:nth-of-type(odd)": {
        borderColor: theme.palette.secondary.main,
        borderWidth: "4px",
        borderStyle: "solid",
      },

      // Spacing between rows
      "&:not(:last-child)": {
        marginBottom: `${theme.spacing(6)}`,
      },
      grid: {
        figure: {
          "&:nth-of-type(odd)": {},
        },
        // maxWidth: 300,
        // margin: "30px auto",
      },
    },
    rowfigure: {
       margin: "30px 10px",
    },

    left: {
      position: "relative",
    },
    image: {
      boxShadow: "rgb(0 0 0 / 15%) 0px 2px 14px 0px, rgb(101 111 119 / 35%) 0px 30px 60px -12px",
      height: "auto",
      maxWidth: "100%",
      // minWidth: "800px",
    },
  }));

  const classes = useStyles();

  const items = [
    {
      key: "a0",
      title: "Flexible & Customizable",
      description: "Organize your people the way you want",
      list: [
        {
          key: "aa0",
          value: "Organize with Sorting and Filtering",
        },
        {
          key: "aa1",
          value: "Customize your own views",
        },
        {
          key: "aa2",
          value: "Sort by name, working hours and presence",
        },
      ],
      styleType: "right",
      image: "images/CM2.webp",
      altText:"Highly Configurable"
    },
    {
      key: "b1",
      title: "Easy to use, efficient interface",
      description: "Time saving features for better collaboration",
      list: [
        {
          key: "bb0",
          value: "Select multiple people or a single user",
        },
        { 
          key: "bb1", 
          value: "Easily create group chats or calls" },
        {
          key: "bb2",
          value: "Click phone numbers to start calls",
        },
      ],
      styleType: "left",
      image: "images/CM3.webp",
      altText:"Better Collaboration App for Teams"
    },
    // {
    //   key: 2,
    //   title: "Microsoft Graph Powered",
    //   description: "Data driven views",
    //   list: [
    //     {
    //       key: 0,
    //       value: "See members of your groups",
    //     },
    //     {
    //       key: 1,
    //       value: "Explore the Global Address List",
    //     },
    //     {
    //       key: 2,
    //       value: "Automatic views of your Team and Distribution Lists",
    //     },
    //   ],
    //   styleType: "left",
    //   image: "images/contactsmanager2.png",
    //   altText:"Insights into your Teams"
    // },
    // {
    //   key: 3,
    //   title: "Configuration features",
    //   description: "Make contacts your own",
    //   list: [
    //     {
    //       key: 0,
    //       value: "Create your own contact folders & move within groups",
    //     },
    //     {
    //       key: 1,
    //       value: "Add new contacts that sync with Outlook",
    //     },
    //     {
    //       key: 2,
    //       value: "Edit contact details within Teams",
    //     },
    //   ],
    //   styleType: "right",
    //   image: "images/contactsmanager2.png",
    //   altText:"Contacts Manager for Teams"
    // },

    {
      key: "c4",
      title: "Work better together",
      description: "Considerate collaboration",
      list: [
        {
          key: "cc0",
          value: "Instantly see who is free with Presence",
        },
        {
          key: "cc1",
          value: "Working hours and local times",
        },
        {
          key: "cc2",
          value: "View schedules of one or more colleagues",
        },
      ],
      styleType: "left",
      image: "images/CM5.webp",
      altText:"Collaboration App for Teams"
    },
  ];
  //console.log(items);

  return (
    <Section
    id={props.name}
      // bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography className={classes.text1} variant="h2" gutterBottom={true}>
              Finally, a logical solution to contacts management in Teams 
              </Typography>
              <Typography className={classes.text1} variant="h5" gutterBottom={true}>
                  Organize, move, bulk select, quick actions and more. 
              </Typography>
              <Typography className={classes.text1} variant="h5" gutterBottom={true}>
                  Make Teams even more powerful. Put people first 
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container
        id="featureContainer"
        // maxWidth="md"
        // disableGutters={true}
        className={classes.itemsContainer}
      >
        {items.map((item, index) => (
          <Grid className={classes.row} container={true} alignItems="center" key={index} id={"row" + index}>
            <Grid item={true} xs={12} md={12}>
              <Box
                textAlign={{
                  xs: "center",
                  md: "center",
                }}
              >
                <Typography className={classes.text2} variant="h5" gutterBottom={true}>
                  {item.title}
                </Typography>
                <Typography className={classes.text1} variant="h4">
                  {item.description}
                </Typography>
                <Grid
                  // className={classes.row}
                  container={true}
                  // spacing={4}
                  direction="column"
                  id={item.key}
                >
                  <Box
                    textAlign={{
                      xs: "center",
                      md: "center",
                      lg: "center",
                    }}
                    sx={{
                      margin: 4,
                    }}
                  >
                    {item.list.map((listitem, index) => (
                      <Typography className={classes.text3} variant="h6" id={listitem.key}>
                        {listitem.value}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
              </Box>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <figure className={classes.rowfigure}>
                <img src={item.image} alt={item.altText} className={classes.image} />
              </figure>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Section>
  );
}

export default FeaturesSection;
