import React, { useState } from "react";
import Section from "./Section";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "./../util/router.js";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useAuth } from "./../util/auth.js";
import { useDarkMode } from "./../util/theme.js";
import { makeStyles } from "@mui/styles";
import { CtaButton } from "../components/CtaSection";
import { useTheme } from "@mui/styles";

function Navbar(props) {
  const useStyles = makeStyles((theme) => ({
    logo: {
      height: 58,
      marginRight: theme.spacing(2),
    },
    drawerList: {
      width: 250,
    },
    spacer: {
      flexGrow: 1,
    },
    text2: {
      color: theme.palette.primary.secondary,
      fontWeight: 600,
    },
    customButton:{
      maxWidth:"125px",
      lineHeight:"1.0"
    }
  }));

  const classes = useStyles();
  const theme = useTheme();

  const auth = useAuth();
  const darkMode = "";useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo = props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container 
        // disableGutters={true}
        >
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
          
              <CtaButton></CtaButton>

            {/* <Button component={Link} to={"/calender"}>
              <Typography variant="h6">Book a Demo</Typography>
            </Button> */}

            {/* <div className={classes.spacer} /> */}
            <Hidden mdUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
                >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden mdDown={true} implementation="css">
              {/* <Button component={Link} to={"https://365apps.io"}>
                <Typography >Products</Typography>
              </Button> */}
              <Button component={Link} to={"/"}  className={classes.text2}>
                <Typography variant="h6"  className={classes.text2}>
                  Home
                </Typography>
              </Button>
              <Button component={Link} to={"/pricing"}>
                <Typography variant="h6">Pricing</Typography>
              </Button>
              <Button component={Link} to={"/faq"}>
                <Typography variant="h6">FAQs</Typography>
              </Button>
              <Button component={Link} to={"/contact"}>
                <Typography variant="h6">Contact</Typography>
              </Button>
             
              <Button 
              className={classes.customButton}
              // component={Link} 
              target="_blank"
              href={"https://365apps.io/"}
              >
                <Typography variant="body">Our Other Products</Typography>
              </Button>
      
   
              {/* {!auth.user && ( 
                 <Button color="inherit" component={Link} to="/auth/signin">
                  <Typography variant="h6">Sign in</Typography>
                 </Button>
               )} */}

              {/* {auth.user && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    Account
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={menuState && menuState.id === "account-menu" ? true : false}
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/dashboard">
                      Dashboard
                    </MenuItem>

                    <MenuItem component={Link} to="/settings/general">
                      Settings
                    </MenuItem>

                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )} */}

      

              {/* <IconButton color="inherit" onClick={darkMode.toggle} style={{ opacity: 0.6 }}>
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton> */}
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List className={classes.drawerList} onClick={() => setDrawerOpen(false)}>
          <ListItem button={true} component={Link} to="/">
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/">
            <CtaButton size="1rem"></CtaButton>
          </ListItem>
          <ListItem button={true} component={Link} to="/pricing">
            <ListItemText>Pricing</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/faq">
            <ListItemText>Faqs</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/contact">
            <ListItemText>Contact</ListItemText>
          </ListItem>


          <ListItem button={true} 
          component={Button}
          href={"https://365apps.io/"}
          //  to="/contact"
           >
            <ListItemText>Other Products</ListItemText>
          </ListItem>

          {!auth.user && (
            <ListItem button={true} component={Link} to="/auth/signin">
              <ListItemText>Sign in</ListItemText>
            </ListItem>
          )}

          {auth.user && (
            <>
              <ListItem button={true} component={Link} to="/dashboard">
                <ListItemText>Dashboard</ListItemText>
              </ListItem>

              <ListItem button={true} component={Link} to="/settings/general">
                <ListItemText>Settings</ListItemText>
              </ListItem>

              <Divider />
              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItem>
            </>
          )}

          {/* <ListItem>
            <IconButton color="inherit" onClick={darkMode.toggle} style={{ opacity: 0.6 }}>
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
          </ListItem> */}
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
